.movie-poster {
    height: 300px;
}

.card  {
    width: 250px;
}

.card:hover {
    border: 2px solid #000;
}

.card-title {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}