.danger-button:hover {
    border: 2px solid #dc3545;
}

.primary-button:hover {
    border: 2px solid #007bff;
}

.input-error-message {
    font-size: 14px;
    line-height: 1;
    margin-top: 10px;
}